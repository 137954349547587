import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { NouisliderModule } from 'ng2-nouislider';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
// import { AgmCoreModule } from '@agm/core';

import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';

import { PagesComponent } from './pages.component';
import { AboutComponent } from './about/about.component';
import { ProductServicesComponent } from './product-services/product-services.component';
import { ConnectComponent } from './connect/connect.component';
import { IndustriesServeComponent } from './industries-serve/industries-serve.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        // NgbModule,
        // NouisliderModule,
        // JwBootstrapSwitchNg2Module,
        CarouselModule
        // InfiniteScrollModule
    ],
    declarations: [
        LandingComponent,
        LoginComponent,
        PagesComponent,
        AboutComponent,
        ProductServicesComponent,
        ConnectComponent,
        IndustriesServeComponent 
    ]
})
export class ExamplesModule { }
