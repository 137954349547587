import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './pages/landing/landing.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutComponent } from './pages/about/about.component';
import { ConnectComponent } from './pages/connect/connect.component';
import { ProductServicesComponent } from './pages/product-services/product-services.component';
import { IndustriesServeComponent } from './pages/industries-serve/industries-serve.component'

const routes: Routes =[
    { path: '', component: LandingComponent },
    // { path: 'about', component: AboutComponent },
    // { path: 'product-services', component: ProductServicesComponent }
    // { path: '', redirectTo: 'index', pathMatch: 'full' },
    { path: 'about',                component: AboutComponent },
    { path: 'connect',              component: ConnectComponent },
    { path: 'products',             component: ProductServicesComponent },
    { path: 'industries-served',     component: IndustriesServeComponent },
    { path: 'examples/landing',     component: LandingComponent },
    { path: 'examples/login',       component: LoginComponent }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
