import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-industries-serve',
  templateUrl: './industries-serve.component.html',
  styleUrls: ['./industries-serve.component.scss']
})

// export class IndustryArr {
//   title: string;
//   img: string;
//   altText: string;
// }

export class IndustriesServeComponent implements OnInit {
  data : Date = new Date();
  industryData: any[] = [
    {
      title:'Textile Industries',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/textiles.png',
      altText:'Global Manufacturing Supplier to Textile Industry'
    },
    {
      title:'Printing',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/printing_press.png',
      altText:'Global Manufacturing Supplier to Packaging Industry'
    },
    {
      title:'Automotive',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/automotive.jpg',
      altText:'Global Manufacturing Supplier to Automotive Industry'
    },
    {
      title:'Construction Machinery',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/construction.jpg',
      altText:'Global Manufacturing Supplier to Construction Industry'
    },
    {
      title:'Food & Beverage',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/beverage.jpg',
      altText:'Global Manufacturing Supplier to Food Industry'
    },
    {
      title:'Transportation',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/transportation.jpg',
      altText:'Global Manufacturing Supplier to Transportation Industry'
    },
    {
      title:'Safety',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/safety.jpg',
      altText:'Global Manufacturing Supplier to Safety Industry'
    },
    {
      title:'Pharma',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/pharma.png',
      altText:'Global Manufacturing Supplier to Pharma Industry'
    },
    {
      title:'HVAC',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/hvac.jpg',
      altText:'Global Manufacturing Supplier to Pharma Industry'
    },
    {
      title:'Lubricant & Petrochemical',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/lubricant.jpg',
      altText:'Global Manufacturing Supplier to Petrochemical Industry'
    },
    {
      title:'Industrial Packaging',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/pack_material.png',
      altText:'Global Manufacturing Supplier to Packaging Industry'
    },
    {
      title:'Agricultural & Farm Equipment',
      img:'https://jupiter-mark-1.s3.amazonaws.com/emmcart/images/farm.jpg',
      altText:'Global Manufacturing Supplier to Agriculture and Farm Equipment Industry'
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

  onScroll() {
    console.log('scrolled!!');
  }

}
