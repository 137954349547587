import { Component, OnInit } from '@angular/core';
// import * as  from '';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  data : Date = new Date();
  focus;
  focus1;
  customerAffiliationData: any[];
  reviewData: any[];
  cstAffCarouselOpt: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 3
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    },
    nav: false
  }

  revCarouselOpt: OwlOptions = {
    loop: true,
    autoplay:true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: false
  }

  constructor() {
    this.customerAffiliationData = [
      { imgsrc:'assets/img/customer_logo/arvind.jpg', dataMerge: 2, width: 250},
      { imgsrc:'assets/img/customer_logo/bonjour.jpg', dataMerge: 1, width: 250},
      { imgsrc:'assets/img/customer_logo/grasim.jpg', width: 250},
      { imgsrc:'assets/img/customer_logo/dupont.png', dataMerge: 3},
      { imgsrc:'assets/img/customer_logo/jockey.jpg', width: 250},
      { imgsrc:'assets/img/customer_logo/nbc.jpg', dataMerge: 2},
      { imgsrc:'assets/img/customer_logo/schaeffler.png', width: 250},
      { imgsrc:'assets/img/customer_logo/wellspun.jpg', width: 250},
      { imgsrc:'assets/img/customer_logo/gulf.png', width: 250},
      { imgsrc:'assets/img/customer_logo/wilmar.png', width: 250},
      // { imgsrc:'assets/img/customer_logo/marico.jpg', width: 250},
      { imgsrc:'assets/img/customer_logo/exide.jpg', width: 250},
      { imgsrc:'assets/img/customer_logo/craftsman.png', width: 250},
      { imgsrc:'assets/img/customer_logo/indorama.jpg', width: 250}
    ];

    this.reviewData = [
      { reviewBy: "Wilmar International", reviewContent: "Your products and services are great. We get prompt, courteous replies from you and your company. The parts we order sometimes arrive before expected date, which is great… I would like to see you stock a wider selection of drum gaskets and plugs, i.e. VGII gaskets. Keep up the good work." },
      { reviewBy: "Schaeffler India Ltd.", reviewContent: "When EmmCart tells you they can get that part made on time and at the agreed price you can take that to the bank…We tried finding a manufacturer in China ourselves but we eventually just gave up—too much hassle—too much uncertainty. Working with EmmCart's Custom was a breath of fresh air." },
      { reviewBy: "Gulf Oil Lubricants Ltd.", reviewContent: "For years we have purchased components from EmmCart and come to rely on the quality of their products and excellent customer service, which meets our technical requirements. EmmCart Solutions has been our favorite vendor and this trust relationship will continue for further longer times. " },
      { reviewBy: "Welspun India Ltd.", reviewContent: "What started out as the purchase of a single item has since grown into a vendor that warehouses and supplies more than 50 separate items for us, and that number is sure to increase. With EmmCart’s long term commitment to us, they continue to provide fast and responsive delivery." },
      { reviewBy: "Indorama Ventures Ltd.", reviewContent: "EmmCart’s support staff is attentive, to say the least. Our orders are on time, supported by strategically stocked materials. The products offered are high Quality and Consistent. In the odd chance we mis-order, EmmCart will alert us of an  aberration, working diligently to re-mediate the problem." }
    ]
   }

  ngOnInit() {
    // var Header = new ('.-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    // navbar.classList.add('navbar-transparent');
  }
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('landing-page');
    var navbar = document.getElementsByTagName('nav')[0];
    // navbar.classList.remove('navbar-transparent');
  }

  returnTrimContent(content: string){
    // var trimmedContentLength = content.trim().length;
    var trimmedContentLength = content.length;
    // if(trimmedContentLength > 180){
    //   return content.substr(0, 180)+'. . .'+<h1>'Read More'</h1>
    // } else {
      return `${content}`
    // }
    
  }
}
